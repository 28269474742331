<template lang="pug">
div
  slot(name="activator" :toggle="toggle")
    v-btn(small color='primary'
      @click="toggle"
      :disabled="disabled"
      outlined
    ) suggest Mijn
  v-dialog(v-model="showing" scrollable max-width=600)
    courseChooseProvider(:item="formData" @input:course="updateCourse")
      template(v-slot:default="courseChoose")
        v-card(data-test="c-set-modal")
          v-card-title Suggest MIJN
          v-card-text
            filter-autocomplete(
              :value="formData.course.city"
              @input="courseChoose.loadCoursesDatesByLocation"
              :getItems="getCourseLocation"
              label="Course Location"
              item-value="ID"
              item-text="name"
              test-name="c-mijn-location-course"
              :error="formErrors.hasError('courseLocation')"
              :error-messages="formErrors.fields.courseLocation"
            )
            v-autocomplete(
              :value="formData.course.ID"
              @input="setCourseHandler($event, courseChoose.courses)"
              label="Course"
              :loading="courseChoose.loading"
              :disabled="!courseChoose.courses.length"
              :items="courseChoose.courses"
              data-test="c-mijn-date-course"
              item-text="dateAndName"
              item-value="ID"
              return-object
              :error="formErrors.hasError('courseDate')"
              :error-messages="formErrors.fields.courseDate"
            )
            CMServiceProvider
              examChooseProvider(
                :courseID="formData.course.ID"
                :key="formData.course.ID"
                v-slot="exam"
                :activeExam="formData.exam"
              )
                exam-autoselect(
                  :list="exam.list"
                  v-slot="autoselect"
                  @autoselect="fillExam(exam.list, $event)"
                )
                  span.d-flex.align-center.mijn-exam
                    v-select(
                      v-model="formData.exam.ID"
                      :items="exam.list"
                      :loading="exam.loading"
                      :disabled="!exam.hasCourse"
                      @autoselect="fillExam(exam.list, $event)"
                      @change="fillExam(exam.list, $event)"
                      item-text="text"
                      item-value="ID"
                      label="Exam datetime"
                      :background-color="autoselect.hasMultipleExams ? 'warning' : ''"
                      :class="{'multiple-exams': autoselect.hasMultipleExams}"
                      :error="formErrors.hasError('exam')"
                      :error-messages="formErrors.fields.exam"
                    )

            v-row
              v-col(cols="6")
                filter-autocomplete(
                  v-model="formData.location"
                  :getItems="getExamLocation"
                  label="Exam Location"
                  :error="formErrors.hasError('location')"
                  :error-messages="formErrors.fields.location"
                )

              v-col(cols="6")
                fieldDatepicker(
                  v-model="formData.examDate"
                  label="Exam date"
                  :min="currentDate"
                  left
                  :error="formErrors.hasError('examDate')"
                  :error-messages="formErrors.fields.examDate"
                )

              v-col(cols="6")
                timepicker(
                  v-model="formData.timeFrom"
                  label="Time from"
                  :hide-details="false"
                  :error="formErrors.hasError('timeFrom')"
                  :error-messages="formErrors.fields.timeFrom"
                )

              v-col(cols="6")
                timepicker(
                  v-model="formData.timeTo"
                  label="Time to"
                  :hide-details="false"
                  :error="formErrors.hasError('timeTo')"
                  :error-messages="formErrors.fields.timeTo"
                )
            v-text-field(
              v-model="formData.subject"
              :error="formErrors.hasError('subject')"
              :error-messages="formErrors.fields.subject"
              label="Subject"
              :loading="templateLoading"
            )

            v-textarea(
              v-model="formData.content"
              label="Content"
              :loading="templateLoading"
            )

          v-card-actions
            v-btn(color="warning" text @click="saveTemplate") save template
            v-spacer
            v-btn(color="error" text @click="toggle" data-test="c-set-close") Close
            v-btn(color="success" text @click="submit" data-test="c-set-submit") Apply
</template>
<script>
import errorsMixin from '@/mixins/errors.mixin'
import FormErrors from "@/util/form-errors"
import { mapGetters, mapActions } from 'vuex'
import { DATETIME, REQUIRED, DATE_INDIAN } from '@/util/validation-rules'
import { BULK_MIJN, CBR_STATUS, SUGGEST_MIJN_TEMPLATE, API_ATTRIBUTES_NESTED, SUGGEST_MIJN_SUBJECT  } from '../core/candidates-const'
import { convertToDefaultDateFormat, dateFormating, convertToDefaultBackendFormat } from '../../../../../util'
import CourseModel from '../../../models/courseModel'
import { formattedDate } from '../../../../../util/filters'
import CMExamConfig from '../../course_managment/classes/CMExamConfig'
import { DATE_DEFAULT_REGXP, STORAGE } from '../../../../../util/const'

export default {
  mixins: [errorsMixin],
  props: {
    disabled: Boolean,
    activeItems: Array
  },
  data: () => ({
    formData: {
      location: null,
      exam: new CMExamConfig(),
      course: new CourseModel(),
      subject: null,
      content: null,
      examDate: null
    },
    currentDate: convertToDefaultBackendFormat(new Date(Date.now())),
    showing: false,
    formErrors: new FormErrors(),
    templateLoading: false,
    apiAttrs: {
      course_id: 'courseDate',
      content: 'content'
    },
  }),

  inject: ['svc'],

  methods: {
    ...mapActions({
      getExamLocation: 'crmExamLocation/list',
      getCourseLocation: 'crmCourseCities/list',
    }),

    async getTemplateData() {
      try {
        this.templateLoading = true
        let subjectField = STORAGE.SUGGEST.MIJN.SUBJECT
        let contentField = STORAGE.SUGGEST.MIJN.CONTENT
        let res = await this.$store.dispatch('storage/get', [subjectField, contentField])
        this.formData.content = res[contentField] ? res[contentField] : SUGGEST_MIJN_TEMPLATE
        this.formData.subject = res[subjectField] || SUGGEST_MIJN_SUBJECT
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
      finally {
        this.templateLoading = false
      }
    },

    async saveTemplate() {
      try {
        this.templateLoading = true
        let subjectField = STORAGE.SUGGEST.MIJN.SUBJECT
        let contentField = STORAGE.SUGGEST.MIJN.CONTENT
        let res = await this.$store.dispatch('storage/set', {
          [subjectField]: this.formData.subject,
          [contentField]: this.formData.content
        })
        this.$notify({type: 'success', text: 'template saved'})
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.templateLoading = false
      }
    },

    toggle() {
      this.showing = !this.showing
    },

    submit() {
      if(!this.validate(this.formErrors)) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return
      }
      this.send()
    },

    validate(validator) {
      validator.resetFields()
      this.validateDate(validator)
      this.validateRequired(this.formData.subject, 'subject', validator)
      this.validateTime(this.formData.timeFrom, 'timeFrom', validator)
      this.validateTime(this.formData.timeTo, 'timeTo', validator)
      this.validateRequired('location', this.formData.location, validator)
      this.validateCourse(this.formData.course, validator)
      return validator.isValid()
    },

    validateTime(val, field, validator) {
      if(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(val)) return true
      validator.invalidField(field, 'Not correct time')
    },


    validateRequired(val, field, validator) {
      let match = REQUIRED(val)
      if(match === true) return true
      validator.invalidField(field, match)
      return false
    },

    validateCourse(course, validator) {
      if(!course.ID) {
        validator.invalidField('courseDate', 'field required')
      }
      if(!course.city) {
        validator.invalidField('courseLocation', 'field required')
      }
    },

    validateDate(validator) {
      if(!(DATE_DEFAULT_REGXP.test(this.formData.examDate)))
        validator.invalidField(
          'examDate',
          'Date should be in DD-MM-YYYY format'
        )
    },

    async send(payload) {
      try {
        let ids = this.activeItems.map(item => item.ID)
        let res = await this.svc().suggestMijn(ids, this.formData)
        this.$notify({text: 'Set MIJN Exam started', type: 'success'})
        this.toggle()
        this.$emit('send', res)
      } catch (error) {
        console.log(error)
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: this.apiAttrs
        });
        if(error.invalid_fields && error.invalid_fields.exam && error.invalid_fields.exam.exam_date) {
          this.formErrors.invalidField('examDate', error.invalid_fields.exam.exam_date)
        }
      }
    },

    reset() {
      this.formData = {
        location: null,
        exam: new CMExamConfig(),
        course: new CourseModel(),
        examDate: '',
        timeFrom: '',
        timeTo: ''
      }
      this.getTemplateData()
    },

    updateCourse(course) {
      this.formData.course = course
    },

    setCourseHandler(course, courses) {
      this.formData.course = course
      this.clearExam()
    },

    updateVal(val, field) {
      this.formData[field] = val
    },

    clearExam() {
      this.formData.exam = new CMExamConfig()
    },

    checkIfNeedExamToAutofill(exams, examID) {
      this.formData.exam.ID = examID
      if(this.formData.examDate || this.formData.timeFrom || this.formData.timeTo || this.formData.location)  return
      this.fillExam(exams, examID)
    },

    fillExam(exams, examID) {
      this.formData.exam.ID = examID
      let selectedExam = exams.find(exam => exam.ID === examID)
      if(!selectedExam) return

      let examDate = convertToDefaultDateFormat(selectedExam.date)
      if (selectedExam.dateFrom && selectedExam.dateTo) examDate = convertToDefaultDateFormat(selectedExam.dateFrom)
      this.formData.examDate = examDate

      this.formData.timeFrom = selectedExam.timeFrom
      this.formData.timeTo = selectedExam.timeTo
      this.formData.location = selectedExam.location
    }
  },

  watch: {
    showing(isShowing) {
      if(!isShowing) {
        this.reset()
        return
      }
      this.getTemplateData()
    }
  },

  components: {
    filterAutocomplete: () => import('@/components/global/FilterAutocomplete.vue'),
    timepicker: () => import('@/components/global/Timepicker.vue'),
    fieldDatepicker: () => import('@/components/global/FieldDatepicker.vue'),
    examChooseProvider: () => import('./reservation/CandidateExamChooseProvider.vue'),
    modalBody: () => import('@/app/admin/modules/course_managment/components/cm_exam/CMExamModalBody.vue'),
    CourseChooseProvider: () => import('./reservation/CandidateCourseChooseProvider.vue'),
    configCreateProvider: () => import('@/app/admin/modules/course_managment/components/cm_exam/CMExamAddProvider.vue'),
    configCreateValidator: () => import('@/app/admin/modules/course_managment/components/cm_exam/CMCreateValidationProvider.vue'),
    configEditProvider: () => import('@/app/admin/modules/course_managment/components/cm_exam/CMExamEditProvider.vue'),
    examAutoselect: () => import('@/app/admin/modules/course_managment/components/cm_exam/CMExamAutoselect.vue'),

    CMServiceProvider: () => import('@/app/admin/modules/course_managment/components/CMServiceProvider.vue'),
  }
}
</script>

<style lang="scss" scoped>
.mijn-exam::v-deep .v-select__selection--comma {
  white-space: initial;
}
</style>
